import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import PostSingle from "../components/Blog/PostSingle/PostSingle"
import styles from "./post-list.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"

const PostListTemplate = (props) => {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/post` : `/post/${currentPage - 1}`
  const nextPage = `/post/${currentPage + 1}`
  const { data } = props

  const getCategories = items => {
    let tempItems = items.map(cat => {
      return cat.node.category.title
    })
    let tempCategories = new Set(tempItems)
    let categories = Array.from(tempCategories)
    categories = ["All", ...categories]
    return categories
  }

  const [allCategories] = useState(getCategories(data.posts.edges))
  const [filteredCategories, setFilteredCategories] = useState(data.posts.edges)
  const [items] = useState(data.posts.edges)

  const handleCategories = (category) => {
    let tempItems = items
    if (category === "All") {
      setFilteredCategories(tempItems)
    } else {
      let items = tempItems.filter(({ node }) => node.category.title === category)
      setFilteredCategories(items)
    }
  }

  if (data.posts.edges.length > 0 && filteredCategories.length > 0) {
    return (
      <Layout>
        <SEO title='Posts' description='The Jolly Mommy Blog Posts'/>
        <section>
          <div className="container">
            <div className='row'>
              <div className='col-md-9'>
                {filteredCategories.map(({ node }) => {
                  return (
                    <div key={node.id} className='overflow-hidden'>
                      <PostSingle post={node}/>
                    </div>
                  )
                })}
              </div>
              <div className='col-md-3'>
                <h3 className='text-center text-dark'>Categories</h3>
                <div className='text-center'>
                  {allCategories.map((category, index) => {
                    return (
                      <p key={index} className='text-uppercase'>
                        <button type='button' className='btn btn-outline-dark m-2' onClick={() => {
                          handleCategories(category)
                        }}>
                          {category}
                        </button>
                      </p>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <section className={styles.links}>
            {
              !isFirst &&
              <AniLink fade to={prevPage} className={styles.link}>
                Prev
              </AniLink>
            }
            {Array.from({ length: numPages }, (_, i) => {
              return <AniLink key={i} fade to={`/post/${i === 0 ? "" : i + 1}`}
                              className={i + 1 === currentPage ? `${styles.link} ${styles.active}` : `${styles.link}`}>
                {i + 1}
              </AniLink>
            })}
            {
              !isLast &&
              <AniLink fade to={nextPage} className={styles.link}>
                Next
              </AniLink>
            }
          </section>
        </section>
      </Layout>
    )
  } else {
    return (
      <Layout>
        TODO
      </Layout>
    )
  }
}

export const query = graphql`
    query getPosts($skip: Int!, $limit: Int!) {
        posts: allContentfulPost(skip: $skip, limit: $limit, sort:{fields: createdAt, order: DESC} filter: {title: {ne: "DO NOT DELETE"}}) {
            edges {
                node {
                    slug
                    title
                    category {
                        title
                    }
                    id: contentful_id
                    createdAt(formatString: "Do MMMM, Y")
                    mainImage {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                    content {
                        json
                    }
                }
            }
        }
    }
`

export default PostListTemplate
